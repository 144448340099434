.ql-editor {
  min-height: 200px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
  padding: 0;
}
.ql-editor.ql-blank::before {
  margin-top: 10px;
}

.ql-container.ql-snow {
  padding: 0 12px;
}
.ql-align-center {
  text-align: center;
}
.ql-align-right {
  text-align: end;
}
.ql-align-justify {
  text-align: justify;
}

.ql-editor a {
  text-decoration: underline;
  color: #305ae4;
}

html.unreset {
  box-sizing: content-box;
}

.unreset body {
  margin: 8px;
  line-height: normal;
}
.unreset p {
  margin: 1em 0;
}
.unreset blockquote,
.unreset figure {
  margin: 1em 40px;
}
.unreset q {
  quotes: '“' '”' '‘' '’';
}
.unreset q:before {
  content: open-quote;
}
.unreset q:after {
  content: close-quote;
}
.unreset hr {
  border: 1px inset;
  box-sizing: border-box;
  margin: 0.5em auto;
}
.unreset h1 {
  font-size: 2em;
  font-weight: bold;
  margin: 0.67em 0;
}
.unreset h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0.83em 0;
}
.unreset h3 {
  font-size: 1.17em;
  font-weight: bold;
  margin: 1em 0;
}
.unreset h4 {
  font-size: 1em;
  font-weight: bold;
  margin: 1.33em 0;
}
.unreset h5 {
  font-size: 0.83em;
  font-weight: bold;
  margin: 1.67em 0;
}
.unreset h6 {
  font-size: 0.67em;
  font-weight: bold;
  margin: 2.33em 0;
}
.unreset article h1,
.unreset aside h1,
.unreset nav h1,
.unreset section h1 {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0.83em 0;
}
.unreset article article h1,
.unreset article aside h1,
.unreset article nav h1,
.unreset article section h1,
.unreset aside article h1,
.unreset aside aside h1,
.unreset aside nav h1,
.unreset aside section h1,
.unreset nav article h1,
.unreset nav aside h1,
.unreset nav nav h1,
.unreset nav section h1,
.unreset section article h1,
.unreset section aside h1,
.unreset section nav h1,
.unreset section section h1 {
  font-size: 1.17em;
  font-weight: bold;
  margin: 1em 0;
}
.unreset article article article h1,
.unreset article article aside h1,
.unreset article article nav h1,
.unreset article article section h1,
.unreset article aside article h1,
.unreset article aside aside h1,
.unreset article aside nav h1,
.unreset article aside section h1,
.unreset article nav article h1,
.unreset article nav aside h1,
.unreset article nav nav h1,
.unreset article nav section h1,
.unreset article section article h1,
.unreset article section aside h1,
.unreset article section nav h1,
.unreset article section section h1,
.unreset aside article article h1,
.unreset aside article aside h1,
.unreset aside article nav h1,
.unreset aside article section h1,
.unreset aside aside article h1,
.unreset aside aside aside h1,
.unreset aside aside nav h1,
.unreset aside aside section h1,
.unreset aside nav article h1,
.unreset aside nav aside h1,
.unreset aside nav nav h1,
.unreset aside nav section h1,
.unreset aside section article h1,
.unreset aside section aside h1,
.unreset aside section nav h1,
.unreset aside section section h1,
.unreset nav article article h1,
.unreset nav article aside h1,
.unreset nav article nav h1,
.unreset nav article section h1,
.unreset nav aside article h1,
.unreset nav aside aside h1,
.unreset nav aside nav h1,
.unreset nav aside section h1,
.unreset nav nav article h1,
.unreset nav nav aside h1,
.unreset nav nav nav h1,
.unreset nav nav section h1,
.unreset nav section article h1,
.unreset nav section aside h1,
.unreset nav section nav h1,
.unreset nav section section h1,
.unreset section article article h1,
.unreset section article aside h1,
.unreset section article nav h1,
.unreset section article section h1,
.unreset section aside article h1,
.unreset section aside aside h1,
.unreset section aside nav h1,
.unreset section aside section h1,
.unreset section nav article h1,
.unreset section nav aside h1,
.unreset section nav nav h1,
.unreset section nav section h1,
.unreset section section article h1,
.unreset section section aside h1,
.unreset section section nav h1,
.unreset section section section h1 {
  font-size: 1em;
  font-weight: bold;
  margin: 1.33em 0;
}
.unreset article article article article h1,
.unreset article article article aside h1,
.unreset article article article nav h1,
.unreset article article article section h1,
.unreset article article aside article h1,
.unreset article article aside aside h1,
.unreset article article aside nav h1,
.unreset article article aside section h1,
.unreset article article nav article h1,
.unreset article article nav aside h1,
.unreset article article nav nav h1,
.unreset article article nav section h1,
.unreset article article section article h1,
.unreset article article section aside h1,
.unreset article article section nav h1,
.unreset article article section section h1,
.unreset article aside article article h1,
.unreset article aside article aside h1,
.unreset article aside article nav h1,
.unreset article aside article section h1,
.unreset article aside aside article h1,
.unreset article aside aside aside h1,
.unreset article aside aside nav h1,
.unreset article aside aside section h1,
.unreset article aside nav article h1,
.unreset article aside nav aside h1,
.unreset article aside nav nav h1,
.unreset article aside nav section h1,
.unreset article aside section article h1,
.unreset article aside section aside h1,
.unreset article aside section nav h1,
.unreset article aside section section h1,
.unreset article nav article article h1,
.unreset article nav article aside h1,
.unreset article nav article nav h1,
.unreset article nav article section h1,
.unreset article nav aside article h1,
.unreset article nav aside aside h1,
.unreset article nav aside nav h1,
.unreset article nav aside section h1,
.unreset article nav nav article h1,
.unreset article nav nav aside h1,
.unreset article nav nav nav h1,
.unreset article nav nav section h1,
.unreset article nav section article h1,
.unreset article nav section aside h1,
.unreset article nav section nav h1,
.unreset article nav section section h1,
.unreset article section article article h1,
.unreset article section article aside h1,
.unreset article section article nav h1,
.unreset article section article section h1,
.unreset article section aside article h1,
.unreset article section aside aside h1,
.unreset article section aside nav h1,
.unreset article section aside section h1,
.unreset article section nav article h1,
.unreset article section nav aside h1,
.unreset article section nav nav h1,
.unreset article section nav section h1,
.unreset article section section article h1,
.unreset article section section aside h1,
.unreset article section section nav h1,
.unreset article section section section h1,
.unreset aside article article article h1,
.unreset aside article article aside h1,
.unreset aside article article nav h1,
.unreset aside article article section h1,
.unreset aside article aside article h1,
.unreset aside article aside aside h1,
.unreset aside article aside nav h1,
.unreset aside article aside section h1,
.unreset aside article nav article h1,
.unreset aside article nav aside h1,
.unreset aside article nav nav h1,
.unreset aside article nav section h1,
.unreset aside article section article h1,
.unreset aside article section aside h1,
.unreset aside article section nav h1,
.unreset aside article section section h1,
.unreset aside aside article article h1,
.unreset aside aside article aside h1,
.unreset aside aside article nav h1,
.unreset aside aside article section h1,
.unreset aside aside aside article h1,
.unreset aside aside aside aside h1,
.unreset aside aside aside nav h1,
.unreset aside aside aside section h1,
.unreset aside aside nav article h1,
.unreset aside aside nav aside h1,
.unreset aside aside nav nav h1,
.unreset aside aside nav section h1,
.unreset aside aside section article h1,
.unreset aside aside section aside h1,
.unreset aside aside section nav h1,
.unreset aside aside section section h1,
.unreset aside nav article article h1,
.unreset aside nav article aside h1,
.unreset aside nav article nav h1,
.unreset aside nav article section h1,
.unreset aside nav aside article h1,
.unreset aside nav aside aside h1,
.unreset aside nav aside nav h1,
.unreset aside nav aside section h1,
.unreset aside nav nav article h1,
.unreset aside nav nav aside h1,
.unreset aside nav nav nav h1,
.unreset aside nav nav section h1,
.unreset aside nav section article h1,
.unreset aside nav section aside h1,
.unreset aside nav section nav h1,
.unreset aside nav section section h1,
.unreset aside section article article h1,
.unreset aside section article aside h1,
.unreset aside section article nav h1,
.unreset aside section article section h1,
.unreset aside section aside article h1,
.unreset aside section aside aside h1,
.unreset aside section aside nav h1,
.unreset aside section aside section h1,
.unreset aside section nav article h1,
.unreset aside section nav aside h1,
.unreset aside section nav nav h1,
.unreset aside section nav section h1,
.unreset aside section section article h1,
.unreset aside section section aside h1,
.unreset aside section section nav h1,
.unreset aside section section section h1,
.unreset nav article article article h1,
.unreset nav article article aside h1,
.unreset nav article article nav h1,
.unreset nav article article section h1,
.unreset nav article aside article h1,
.unreset nav article aside aside h1,
.unreset nav article aside nav h1,
.unreset nav article aside section h1,
.unreset nav article nav article h1,
.unreset nav article nav aside h1,
.unreset nav article nav nav h1,
.unreset nav article nav section h1,
.unreset nav article section article h1,
.unreset nav article section aside h1,
.unreset nav article section nav h1,
.unreset nav article section section h1,
.unreset nav aside article article h1,
.unreset nav aside article aside h1,
.unreset nav aside article nav h1,
.unreset nav aside article section h1,
.unreset nav aside aside article h1,
.unreset nav aside aside aside h1,
.unreset nav aside aside nav h1,
.unreset nav aside aside section h1,
.unreset nav aside nav article h1,
.unreset nav aside nav aside h1,
.unreset nav aside nav nav h1,
.unreset nav aside nav section h1,
.unreset nav aside section article h1,
.unreset nav aside section aside h1,
.unreset nav aside section nav h1,
.unreset nav aside section section h1,
.unreset nav nav article article h1,
.unreset nav nav article aside h1,
.unreset nav nav article nav h1,
.unreset nav nav article section h1,
.unreset nav nav aside article h1,
.unreset nav nav aside aside h1,
.unreset nav nav aside nav h1,
.unreset nav nav aside section h1,
.unreset nav nav nav article h1,
.unreset nav nav nav aside h1,
.unreset nav nav nav nav h1,
.unreset nav nav nav section h1,
.unreset nav nav section article h1,
.unreset nav nav section aside h1,
.unreset nav nav section nav h1,
.unreset nav nav section section h1,
.unreset nav section article article h1,
.unreset nav section article aside h1,
.unreset nav section article nav h1,
.unreset nav section article section h1,
.unreset nav section aside article h1,
.unreset nav section aside aside h1,
.unreset nav section aside nav h1,
.unreset nav section aside section h1,
.unreset nav section nav article h1,
.unreset nav section nav aside h1,
.unreset nav section nav nav h1,
.unreset nav section nav section h1,
.unreset nav section section article h1,
.unreset nav section section aside h1,
.unreset nav section section nav h1,
.unreset nav section section section h1,
.unreset section article article article h1,
.unreset section article article aside h1,
.unreset section article article nav h1,
.unreset section article article section h1,
.unreset section article aside article h1,
.unreset section article aside aside h1,
.unreset section article aside nav h1,
.unreset section article aside section h1,
.unreset section article nav article h1,
.unreset section article nav aside h1,
.unreset section article nav nav h1,
.unreset section article nav section h1,
.unreset section article section article h1,
.unreset section article section aside h1,
.unreset section article section nav h1,
.unreset section article section section h1,
.unreset section aside article article h1,
.unreset section aside article aside h1,
.unreset section aside article nav h1,
.unreset section aside article section h1,
.unreset section aside aside article h1,
.unreset section aside aside aside h1,
.unreset section aside aside nav h1,
.unreset section aside aside section h1,
.unreset section aside nav article h1,
.unreset section aside nav aside h1,
.unreset section aside nav nav h1,
.unreset section aside nav section h1,
.unreset section aside section article h1,
.unreset section aside section aside h1,
.unreset section aside section nav h1,
.unreset section aside section section h1,
.unreset section nav article article h1,
.unreset section nav article aside h1,
.unreset section nav article nav h1,
.unreset section nav article section h1,
.unreset section nav aside article h1,
.unreset section nav aside aside h1,
.unreset section nav aside nav h1,
.unreset section nav aside section h1,
.unreset section nav nav article h1,
.unreset section nav nav aside h1,
.unreset section nav nav nav h1,
.unreset section nav nav section h1,
.unreset section nav section article h1,
.unreset section nav section aside h1,
.unreset section nav section nav h1,
.unreset section nav section section h1,
.unreset section section article article h1,
.unreset section section article aside h1,
.unreset section section article nav h1,
.unreset section section article section h1,
.unreset section section aside article h1,
.unreset section section aside aside h1,
.unreset section section aside nav h1,
.unreset section section aside section h1,
.unreset section section nav article h1,
.unreset section section nav aside h1,
.unreset section section nav nav h1,
.unreset section section nav section h1,
.unreset section section section article h1,
.unreset section section section aside h1,
.unreset section section section nav h1,
.unreset section section section section h1 {
  font-size: 0.83em;
  font-weight: bold;
  margin: 1.67em 0;
}
.unreset article article article article article h1,
.unreset article article article article aside h1,
.unreset article article article article nav h1,
.unreset article article article article section h1,
.unreset article article article aside article h1,
.unreset article article article aside aside h1,
.unreset article article article aside nav h1,
.unreset article article article aside section h1,
.unreset article article article nav article h1,
.unreset article article article nav aside h1,
.unreset article article article nav nav h1,
.unreset article article article nav section h1,
.unreset article article article section article h1,
.unreset article article article section aside h1,
.unreset article article article section nav h1,
.unreset article article article section section h1,
.unreset article article aside article article h1,
.unreset article article aside article aside h1,
.unreset article article aside article nav h1,
.unreset article article aside article section h1,
.unreset article article aside aside article h1,
.unreset article article aside aside aside h1,
.unreset article article aside aside nav h1,
.unreset article article aside aside section h1,
.unreset article article aside nav article h1,
.unreset article article aside nav aside h1,
.unreset article article aside nav nav h1,
.unreset article article aside nav section h1,
.unreset article article aside section article h1,
.unreset article article aside section aside h1,
.unreset article article aside section nav h1,
.unreset article article aside section section h1,
.unreset article article nav article article h1,
.unreset article article nav article aside h1,
.unreset article article nav article nav h1,
.unreset article article nav article section h1,
.unreset article article nav aside article h1,
.unreset article article nav aside aside h1,
.unreset article article nav aside nav h1,
.unreset article article nav aside section h1,
.unreset article article nav nav article h1,
.unreset article article nav nav aside h1,
.unreset article article nav nav nav h1,
.unreset article article nav nav section h1,
.unreset article article nav section article h1,
.unreset article article nav section aside h1,
.unreset article article nav section nav h1,
.unreset article article nav section section h1,
.unreset article article section article article h1,
.unreset article article section article aside h1,
.unreset article article section article nav h1,
.unreset article article section article section h1,
.unreset article article section aside article h1,
.unreset article article section aside aside h1,
.unreset article article section aside nav h1,
.unreset article article section aside section h1,
.unreset article article section nav article h1,
.unreset article article section nav aside h1,
.unreset article article section nav nav h1,
.unreset article article section nav section h1,
.unreset article article section section article h1,
.unreset article article section section aside h1,
.unreset article article section section nav h1,
.unreset article article section section section h1,
.unreset article aside article article article h1,
.unreset article aside article article aside h1,
.unreset article aside article article nav h1,
.unreset article aside article article section h1,
.unreset article aside article aside article h1,
.unreset article aside article aside aside h1,
.unreset article aside article aside nav h1,
.unreset article aside article aside section h1,
.unreset article aside article nav article h1,
.unreset article aside article nav aside h1,
.unreset article aside article nav nav h1,
.unreset article aside article nav section h1,
.unreset article aside article section article h1,
.unreset article aside article section aside h1,
.unreset article aside article section nav h1,
.unreset article aside article section section h1,
.unreset article aside aside article article h1,
.unreset article aside aside article aside h1,
.unreset article aside aside article nav h1,
.unreset article aside aside article section h1,
.unreset article aside aside aside article h1,
.unreset article aside aside aside aside h1,
.unreset article aside aside aside nav h1,
.unreset article aside aside aside section h1,
.unreset article aside aside nav article h1,
.unreset article aside aside nav aside h1,
.unreset article aside aside nav nav h1,
.unreset article aside aside nav section h1,
.unreset article aside aside section article h1,
.unreset article aside aside section aside h1,
.unreset article aside aside section nav h1,
.unreset article aside aside section section h1,
.unreset article aside nav article article h1,
.unreset article aside nav article aside h1,
.unreset article aside nav article nav h1,
.unreset article aside nav article section h1,
.unreset article aside nav aside article h1,
.unreset article aside nav aside aside h1,
.unreset article aside nav aside nav h1,
.unreset article aside nav aside section h1,
.unreset article aside nav nav article h1,
.unreset article aside nav nav aside h1,
.unreset article aside nav nav nav h1,
.unreset article aside nav nav section h1,
.unreset article aside nav section article h1,
.unreset article aside nav section aside h1,
.unreset article aside nav section nav h1,
.unreset article aside nav section section h1,
.unreset article aside section article article h1,
.unreset article aside section article aside h1,
.unreset article aside section article nav h1,
.unreset article aside section article section h1,
.unreset article aside section aside article h1,
.unreset article aside section aside aside h1,
.unreset article aside section aside nav h1,
.unreset article aside section aside section h1,
.unreset article aside section nav article h1,
.unreset article aside section nav aside h1,
.unreset article aside section nav nav h1,
.unreset article aside section nav section h1,
.unreset article aside section section article h1,
.unreset article aside section section aside h1,
.unreset article aside section section nav h1,
.unreset article aside section section section h1,
.unreset article nav article article article h1,
.unreset article nav article article aside h1,
.unreset article nav article article nav h1,
.unreset article nav article article section h1,
.unreset article nav article aside article h1,
.unreset article nav article aside aside h1,
.unreset article nav article aside nav h1,
.unreset article nav article aside section h1,
.unreset article nav article nav article h1,
.unreset article nav article nav aside h1,
.unreset article nav article nav nav h1,
.unreset article nav article nav section h1,
.unreset article nav article section article h1,
.unreset article nav article section aside h1,
.unreset article nav article section nav h1,
.unreset article nav article section section h1,
.unreset article nav aside article article h1,
.unreset article nav aside article aside h1,
.unreset article nav aside article nav h1,
.unreset article nav aside article section h1,
.unreset article nav aside aside article h1,
.unreset article nav aside aside aside h1,
.unreset article nav aside aside nav h1,
.unreset article nav aside aside section h1,
.unreset article nav aside nav article h1,
.unreset article nav aside nav aside h1,
.unreset article nav aside nav nav h1,
.unreset article nav aside nav section h1,
.unreset article nav aside section article h1,
.unreset article nav aside section aside h1,
.unreset article nav aside section nav h1,
.unreset article nav aside section section h1,
.unreset article nav nav article article h1,
.unreset article nav nav article aside h1,
.unreset article nav nav article nav h1,
.unreset article nav nav article section h1,
.unreset article nav nav aside article h1,
.unreset article nav nav aside aside h1,
.unreset article nav nav aside nav h1,
.unreset article nav nav aside section h1,
.unreset article nav nav nav article h1,
.unreset article nav nav nav aside h1,
.unreset article nav nav nav nav h1,
.unreset article nav nav nav section h1,
.unreset article nav nav section article h1,
.unreset article nav nav section aside h1,
.unreset article nav nav section nav h1,
.unreset article nav nav section section h1,
.unreset article nav section article article h1,
.unreset article nav section article aside h1,
.unreset article nav section article nav h1,
.unreset article nav section article section h1,
.unreset article nav section aside article h1,
.unreset article nav section aside aside h1,
.unreset article nav section aside nav h1,
.unreset article nav section aside section h1,
.unreset article nav section nav article h1,
.unreset article nav section nav aside h1,
.unreset article nav section nav nav h1,
.unreset article nav section nav section h1,
.unreset article nav section section article h1,
.unreset article nav section section aside h1,
.unreset article nav section section nav h1,
.unreset article nav section section section h1,
.unreset article section article article article h1,
.unreset article section article article aside h1,
.unreset article section article article nav h1,
.unreset article section article article section h1,
.unreset article section article aside article h1,
.unreset article section article aside aside h1,
.unreset article section article aside nav h1,
.unreset article section article aside section h1,
.unreset article section article nav article h1,
.unreset article section article nav aside h1,
.unreset article section article nav nav h1,
.unreset article section article nav section h1,
.unreset article section article section article h1,
.unreset article section article section aside h1,
.unreset article section article section nav h1,
.unreset article section article section section h1,
.unreset article section aside article article h1,
.unreset article section aside article aside h1,
.unreset article section aside article nav h1,
.unreset article section aside article section h1,
.unreset article section aside aside article h1,
.unreset article section aside aside aside h1,
.unreset article section aside aside nav h1,
.unreset article section aside aside section h1,
.unreset article section aside nav article h1,
.unreset article section aside nav aside h1,
.unreset article section aside nav nav h1,
.unreset article section aside nav section h1,
.unreset article section aside section article h1,
.unreset article section aside section aside h1,
.unreset article section aside section nav h1,
.unreset article section aside section section h1,
.unreset article section nav article article h1,
.unreset article section nav article aside h1,
.unreset article section nav article nav h1,
.unreset article section nav article section h1,
.unreset article section nav aside article h1,
.unreset article section nav aside aside h1,
.unreset article section nav aside nav h1,
.unreset article section nav aside section h1,
.unreset article section nav nav article h1,
.unreset article section nav nav aside h1,
.unreset article section nav nav nav h1,
.unreset article section nav nav section h1,
.unreset article section nav section article h1,
.unreset article section nav section aside h1,
.unreset article section nav section nav h1,
.unreset article section nav section section h1,
.unreset article section section article article h1,
.unreset article section section article aside h1,
.unreset article section section article nav h1,
.unreset article section section article section h1,
.unreset article section section aside article h1,
.unreset article section section aside aside h1,
.unreset article section section aside nav h1,
.unreset article section section aside section h1,
.unreset article section section nav article h1,
.unreset article section section nav aside h1,
.unreset article section section nav nav h1,
.unreset article section section nav section h1,
.unreset article section section section article h1,
.unreset article section section section aside h1,
.unreset article section section section nav h1,
.unreset article section section section section h1,
.unreset aside article article article article h1,
.unreset aside article article article aside h1,
.unreset aside article article article nav h1,
.unreset aside article article article section h1,
.unreset aside article article aside article h1,
.unreset aside article article aside aside h1,
.unreset aside article article aside nav h1,
.unreset aside article article aside section h1,
.unreset aside article article nav article h1,
.unreset aside article article nav aside h1,
.unreset aside article article nav nav h1,
.unreset aside article article nav section h1,
.unreset aside article article section article h1,
.unreset aside article article section aside h1,
.unreset aside article article section nav h1,
.unreset aside article article section section h1,
.unreset aside article aside article article h1,
.unreset aside article aside article aside h1,
.unreset aside article aside article nav h1,
.unreset aside article aside article section h1,
.unreset aside article aside aside article h1,
.unreset aside article aside aside aside h1,
.unreset aside article aside aside nav h1,
.unreset aside article aside aside section h1,
.unreset aside article aside nav article h1,
.unreset aside article aside nav aside h1,
.unreset aside article aside nav nav h1,
.unreset aside article aside nav section h1,
.unreset aside article aside section article h1,
.unreset aside article aside section aside h1,
.unreset aside article aside section nav h1,
.unreset aside article aside section section h1,
.unreset aside article nav article article h1,
.unreset aside article nav article aside h1,
.unreset aside article nav article nav h1,
.unreset aside article nav article section h1,
.unreset aside article nav aside article h1,
.unreset aside article nav aside aside h1,
.unreset aside article nav aside nav h1,
.unreset aside article nav aside section h1,
.unreset aside article nav nav article h1,
.unreset aside article nav nav aside h1,
.unreset aside article nav nav nav h1,
.unreset aside article nav nav section h1,
.unreset aside article nav section article h1,
.unreset aside article nav section aside h1,
.unreset aside article nav section nav h1,
.unreset aside article nav section section h1,
.unreset aside article section article article h1,
.unreset aside article section article aside h1,
.unreset aside article section article nav h1,
.unreset aside article section article section h1,
.unreset aside article section aside article h1,
.unreset aside article section aside aside h1,
.unreset aside article section aside nav h1,
.unreset aside article section aside section h1,
.unreset aside article section nav article h1,
.unreset aside article section nav aside h1,
.unreset aside article section nav nav h1,
.unreset aside article section nav section h1,
.unreset aside article section section article h1,
.unreset aside article section section aside h1,
.unreset aside article section section nav h1,
.unreset aside article section section section h1,
.unreset aside aside article article article h1,
.unreset aside aside article article aside h1,
.unreset aside aside article article nav h1,
.unreset aside aside article article section h1,
.unreset aside aside article aside article h1,
.unreset aside aside article aside aside h1,
.unreset aside aside article aside nav h1,
.unreset aside aside article aside section h1,
.unreset aside aside article nav article h1,
.unreset aside aside article nav aside h1,
.unreset aside aside article nav nav h1,
.unreset aside aside article nav section h1,
.unreset aside aside article section article h1,
.unreset aside aside article section aside h1,
.unreset aside aside article section nav h1,
.unreset aside aside article section section h1,
.unreset aside aside aside article article h1,
.unreset aside aside aside article aside h1,
.unreset aside aside aside article nav h1,
.unreset aside aside aside article section h1,
.unreset aside aside aside aside article h1,
.unreset aside aside aside aside aside h1,
.unreset aside aside aside aside nav h1,
.unreset aside aside aside aside section h1,
.unreset aside aside aside nav article h1,
.unreset aside aside aside nav aside h1,
.unreset aside aside aside nav nav h1,
.unreset aside aside aside nav section h1,
.unreset aside aside aside section article h1,
.unreset aside aside aside section aside h1,
.unreset aside aside aside section nav h1,
.unreset aside aside aside section section h1,
.unreset aside aside nav article article h1,
.unreset aside aside nav article aside h1,
.unreset aside aside nav article nav h1,
.unreset aside aside nav article section h1,
.unreset aside aside nav aside article h1,
.unreset aside aside nav aside aside h1,
.unreset aside aside nav aside nav h1,
.unreset aside aside nav aside section h1,
.unreset aside aside nav nav article h1,
.unreset aside aside nav nav aside h1,
.unreset aside aside nav nav nav h1,
.unreset aside aside nav nav section h1,
.unreset aside aside nav section article h1,
.unreset aside aside nav section aside h1,
.unreset aside aside nav section nav h1,
.unreset aside aside nav section section h1,
.unreset aside aside section article article h1,
.unreset aside aside section article aside h1,
.unreset aside aside section article nav h1,
.unreset aside aside section article section h1,
.unreset aside aside section aside article h1,
.unreset aside aside section aside aside h1,
.unreset aside aside section aside nav h1,
.unreset aside aside section aside section h1,
.unreset aside aside section nav article h1,
.unreset aside aside section nav aside h1,
.unreset aside aside section nav nav h1,
.unreset aside aside section nav section h1,
.unreset aside aside section section article h1,
.unreset aside aside section section aside h1,
.unreset aside aside section section nav h1,
.unreset aside aside section section section h1,
.unreset aside nav article article article h1,
.unreset aside nav article article aside h1,
.unreset aside nav article article nav h1,
.unreset aside nav article article section h1,
.unreset aside nav article aside article h1,
.unreset aside nav article aside aside h1,
.unreset aside nav article aside nav h1,
.unreset aside nav article aside section h1,
.unreset aside nav article nav article h1,
.unreset aside nav article nav aside h1,
.unreset aside nav article nav nav h1,
.unreset aside nav article nav section h1,
.unreset aside nav article section article h1,
.unreset aside nav article section aside h1,
.unreset aside nav article section nav h1,
.unreset aside nav article section section h1,
.unreset aside nav aside article article h1,
.unreset aside nav aside article aside h1,
.unreset aside nav aside article nav h1,
.unreset aside nav aside article section h1,
.unreset aside nav aside aside article h1,
.unreset aside nav aside aside aside h1,
.unreset aside nav aside aside nav h1,
.unreset aside nav aside aside section h1,
.unreset aside nav aside nav article h1,
.unreset aside nav aside nav aside h1,
.unreset aside nav aside nav nav h1,
.unreset aside nav aside nav section h1,
.unreset aside nav aside section article h1,
.unreset aside nav aside section aside h1,
.unreset aside nav aside section nav h1,
.unreset aside nav aside section section h1,
.unreset aside nav nav article article h1,
.unreset aside nav nav article aside h1,
.unreset aside nav nav article nav h1,
.unreset aside nav nav article section h1,
.unreset aside nav nav aside article h1,
.unreset aside nav nav aside aside h1,
.unreset aside nav nav aside nav h1,
.unreset aside nav nav aside section h1,
.unreset aside nav nav nav article h1,
.unreset aside nav nav nav aside h1,
.unreset aside nav nav nav nav h1,
.unreset aside nav nav nav section h1,
.unreset aside nav nav section article h1,
.unreset aside nav nav section aside h1,
.unreset aside nav nav section nav h1,
.unreset aside nav nav section section h1,
.unreset aside nav section article article h1,
.unreset aside nav section article aside h1,
.unreset aside nav section article nav h1,
.unreset aside nav section article section h1,
.unreset aside nav section aside article h1,
.unreset aside nav section aside aside h1,
.unreset aside nav section aside nav h1,
.unreset aside nav section aside section h1,
.unreset aside nav section nav article h1,
.unreset aside nav section nav aside h1,
.unreset aside nav section nav nav h1,
.unreset aside nav section nav section h1,
.unreset aside nav section section article h1,
.unreset aside nav section section aside h1,
.unreset aside nav section section nav h1,
.unreset aside nav section section section h1,
.unreset aside section article article article h1,
.unreset aside section article article aside h1,
.unreset aside section article article nav h1,
.unreset aside section article article section h1,
.unreset aside section article aside article h1,
.unreset aside section article aside aside h1,
.unreset aside section article aside nav h1,
.unreset aside section article aside section h1,
.unreset aside section article nav article h1,
.unreset aside section article nav aside h1,
.unreset aside section article nav nav h1,
.unreset aside section article nav section h1,
.unreset aside section article section article h1,
.unreset aside section article section aside h1,
.unreset aside section article section nav h1,
.unreset aside section article section section h1,
.unreset aside section aside article article h1,
.unreset aside section aside article aside h1,
.unreset aside section aside article nav h1,
.unreset aside section aside article section h1,
.unreset aside section aside aside article h1,
.unreset aside section aside aside aside h1,
.unreset aside section aside aside nav h1,
.unreset aside section aside aside section h1,
.unreset aside section aside nav article h1,
.unreset aside section aside nav aside h1,
.unreset aside section aside nav nav h1,
.unreset aside section aside nav section h1,
.unreset aside section aside section article h1,
.unreset aside section aside section aside h1,
.unreset aside section aside section nav h1,
.unreset aside section aside section section h1,
.unreset aside section nav article article h1,
.unreset aside section nav article aside h1,
.unreset aside section nav article nav h1,
.unreset aside section nav article section h1,
.unreset aside section nav aside article h1,
.unreset aside section nav aside aside h1,
.unreset aside section nav aside nav h1,
.unreset aside section nav aside section h1,
.unreset aside section nav nav article h1,
.unreset aside section nav nav aside h1,
.unreset aside section nav nav nav h1,
.unreset aside section nav nav section h1,
.unreset aside section nav section article h1,
.unreset aside section nav section aside h1,
.unreset aside section nav section nav h1,
.unreset aside section nav section section h1,
.unreset aside section section article article h1,
.unreset aside section section article aside h1,
.unreset aside section section article nav h1,
.unreset aside section section article section h1,
.unreset aside section section aside article h1,
.unreset aside section section aside aside h1,
.unreset aside section section aside nav h1,
.unreset aside section section aside section h1,
.unreset aside section section nav article h1,
.unreset aside section section nav aside h1,
.unreset aside section section nav nav h1,
.unreset aside section section nav section h1,
.unreset aside section section section article h1,
.unreset aside section section section aside h1,
.unreset aside section section section nav h1,
.unreset aside section section section section h1,
.unreset nav article article article article h1,
.unreset nav article article article aside h1,
.unreset nav article article article nav h1,
.unreset nav article article article section h1,
.unreset nav article article aside article h1,
.unreset nav article article aside aside h1,
.unreset nav article article aside nav h1,
.unreset nav article article aside section h1,
.unreset nav article article nav article h1,
.unreset nav article article nav aside h1,
.unreset nav article article nav nav h1,
.unreset nav article article nav section h1,
.unreset nav article article section article h1,
.unreset nav article article section aside h1,
.unreset nav article article section nav h1,
.unreset nav article article section section h1,
.unreset nav article aside article article h1,
.unreset nav article aside article aside h1,
.unreset nav article aside article nav h1,
.unreset nav article aside article section h1,
.unreset nav article aside aside article h1,
.unreset nav article aside aside aside h1,
.unreset nav article aside aside nav h1,
.unreset nav article aside aside section h1,
.unreset nav article aside nav article h1,
.unreset nav article aside nav aside h1,
.unreset nav article aside nav nav h1,
.unreset nav article aside nav section h1,
.unreset nav article aside section article h1,
.unreset nav article aside section aside h1,
.unreset nav article aside section nav h1,
.unreset nav article aside section section h1,
.unreset nav article nav article article h1,
.unreset nav article nav article aside h1,
.unreset nav article nav article nav h1,
.unreset nav article nav article section h1,
.unreset nav article nav aside article h1,
.unreset nav article nav aside aside h1,
.unreset nav article nav aside nav h1,
.unreset nav article nav aside section h1,
.unreset nav article nav nav article h1,
.unreset nav article nav nav aside h1,
.unreset nav article nav nav nav h1,
.unreset nav article nav nav section h1,
.unreset nav article nav section article h1,
.unreset nav article nav section aside h1,
.unreset nav article nav section nav h1,
.unreset nav article nav section section h1,
.unreset nav article section article article h1,
.unreset nav article section article aside h1,
.unreset nav article section article nav h1,
.unreset nav article section article section h1,
.unreset nav article section aside article h1,
.unreset nav article section aside aside h1,
.unreset nav article section aside nav h1,
.unreset nav article section aside section h1,
.unreset nav article section nav article h1,
.unreset nav article section nav aside h1,
.unreset nav article section nav nav h1,
.unreset nav article section nav section h1,
.unreset nav article section section article h1,
.unreset nav article section section aside h1,
.unreset nav article section section nav h1,
.unreset nav article section section section h1,
.unreset nav aside article article article h1,
.unreset nav aside article article aside h1,
.unreset nav aside article article nav h1,
.unreset nav aside article article section h1,
.unreset nav aside article aside article h1,
.unreset nav aside article aside aside h1,
.unreset nav aside article aside nav h1,
.unreset nav aside article aside section h1,
.unreset nav aside article nav article h1,
.unreset nav aside article nav aside h1,
.unreset nav aside article nav nav h1,
.unreset nav aside article nav section h1,
.unreset nav aside article section article h1,
.unreset nav aside article section aside h1,
.unreset nav aside article section nav h1,
.unreset nav aside article section section h1,
.unreset nav aside aside article article h1,
.unreset nav aside aside article aside h1,
.unreset nav aside aside article nav h1,
.unreset nav aside aside article section h1,
.unreset nav aside aside aside article h1,
.unreset nav aside aside aside aside h1,
.unreset nav aside aside aside nav h1,
.unreset nav aside aside aside section h1,
.unreset nav aside aside nav article h1,
.unreset nav aside aside nav aside h1,
.unreset nav aside aside nav nav h1,
.unreset nav aside aside nav section h1,
.unreset nav aside aside section article h1,
.unreset nav aside aside section aside h1,
.unreset nav aside aside section nav h1,
.unreset nav aside aside section section h1,
.unreset nav aside nav article article h1,
.unreset nav aside nav article aside h1,
.unreset nav aside nav article nav h1,
.unreset nav aside nav article section h1,
.unreset nav aside nav aside article h1,
.unreset nav aside nav aside aside h1,
.unreset nav aside nav aside nav h1,
.unreset nav aside nav aside section h1,
.unreset nav aside nav nav article h1,
.unreset nav aside nav nav aside h1,
.unreset nav aside nav nav nav h1,
.unreset nav aside nav nav section h1,
.unreset nav aside nav section article h1,
.unreset nav aside nav section aside h1,
.unreset nav aside nav section nav h1,
.unreset nav aside nav section section h1,
.unreset nav aside section article article h1,
.unreset nav aside section article aside h1,
.unreset nav aside section article nav h1,
.unreset nav aside section article section h1,
.unreset nav aside section aside article h1,
.unreset nav aside section aside aside h1,
.unreset nav aside section aside nav h1,
.unreset nav aside section aside section h1,
.unreset nav aside section nav article h1,
.unreset nav aside section nav aside h1,
.unreset nav aside section nav nav h1,
.unreset nav aside section nav section h1,
.unreset nav aside section section article h1,
.unreset nav aside section section aside h1,
.unreset nav aside section section nav h1,
.unreset nav aside section section section h1,
.unreset nav nav article article article h1,
.unreset nav nav article article aside h1,
.unreset nav nav article article nav h1,
.unreset nav nav article article section h1,
.unreset nav nav article aside article h1,
.unreset nav nav article aside aside h1,
.unreset nav nav article aside nav h1,
.unreset nav nav article aside section h1,
.unreset nav nav article nav article h1,
.unreset nav nav article nav aside h1,
.unreset nav nav article nav nav h1,
.unreset nav nav article nav section h1,
.unreset nav nav article section article h1,
.unreset nav nav article section aside h1,
.unreset nav nav article section nav h1,
.unreset nav nav article section section h1,
.unreset nav nav aside article article h1,
.unreset nav nav aside article aside h1,
.unreset nav nav aside article nav h1,
.unreset nav nav aside article section h1,
.unreset nav nav aside aside article h1,
.unreset nav nav aside aside aside h1,
.unreset nav nav aside aside nav h1,
.unreset nav nav aside aside section h1,
.unreset nav nav aside nav article h1,
.unreset nav nav aside nav aside h1,
.unreset nav nav aside nav nav h1,
.unreset nav nav aside nav section h1,
.unreset nav nav aside section article h1,
.unreset nav nav aside section aside h1,
.unreset nav nav aside section nav h1,
.unreset nav nav aside section section h1,
.unreset nav nav nav article article h1,
.unreset nav nav nav article aside h1,
.unreset nav nav nav article nav h1,
.unreset nav nav nav article section h1,
.unreset nav nav nav aside article h1,
.unreset nav nav nav aside aside h1,
.unreset nav nav nav aside nav h1,
.unreset nav nav nav aside section h1,
.unreset nav nav nav nav article h1,
.unreset nav nav nav nav aside h1,
.unreset nav nav nav nav nav h1,
.unreset nav nav nav nav section h1,
.unreset nav nav nav section article h1,
.unreset nav nav nav section aside h1,
.unreset nav nav nav section nav h1,
.unreset nav nav nav section section h1,
.unreset nav nav section article article h1,
.unreset nav nav section article aside h1,
.unreset nav nav section article nav h1,
.unreset nav nav section article section h1,
.unreset nav nav section aside article h1,
.unreset nav nav section aside aside h1,
.unreset nav nav section aside nav h1,
.unreset nav nav section aside section h1,
.unreset nav nav section nav article h1,
.unreset nav nav section nav aside h1,
.unreset nav nav section nav nav h1,
.unreset nav nav section nav section h1,
.unreset nav nav section section article h1,
.unreset nav nav section section aside h1,
.unreset nav nav section section nav h1,
.unreset nav nav section section section h1,
.unreset nav section article article article h1,
.unreset nav section article article aside h1,
.unreset nav section article article nav h1,
.unreset nav section article article section h1,
.unreset nav section article aside article h1,
.unreset nav section article aside aside h1,
.unreset nav section article aside nav h1,
.unreset nav section article aside section h1,
.unreset nav section article nav article h1,
.unreset nav section article nav aside h1,
.unreset nav section article nav nav h1,
.unreset nav section article nav section h1,
.unreset nav section article section article h1,
.unreset nav section article section aside h1,
.unreset nav section article section nav h1,
.unreset nav section article section section h1,
.unreset nav section aside article article h1,
.unreset nav section aside article aside h1,
.unreset nav section aside article nav h1,
.unreset nav section aside article section h1,
.unreset nav section aside aside article h1,
.unreset nav section aside aside aside h1,
.unreset nav section aside aside nav h1,
.unreset nav section aside aside section h1,
.unreset nav section aside nav article h1,
.unreset nav section aside nav aside h1,
.unreset nav section aside nav nav h1,
.unreset nav section aside nav section h1,
.unreset nav section aside section article h1,
.unreset nav section aside section aside h1,
.unreset nav section aside section nav h1,
.unreset nav section aside section section h1,
.unreset nav section nav article article h1,
.unreset nav section nav article aside h1,
.unreset nav section nav article nav h1,
.unreset nav section nav article section h1,
.unreset nav section nav aside article h1,
.unreset nav section nav aside aside h1,
.unreset nav section nav aside nav h1,
.unreset nav section nav aside section h1,
.unreset nav section nav nav article h1,
.unreset nav section nav nav aside h1,
.unreset nav section nav nav nav h1,
.unreset nav section nav nav section h1,
.unreset nav section nav section article h1,
.unreset nav section nav section aside h1,
.unreset nav section nav section nav h1,
.unreset nav section nav section section h1,
.unreset nav section section article article h1,
.unreset nav section section article aside h1,
.unreset nav section section article nav h1,
.unreset nav section section article section h1,
.unreset nav section section aside article h1,
.unreset nav section section aside aside h1,
.unreset nav section section aside nav h1,
.unreset nav section section aside section h1,
.unreset nav section section nav article h1,
.unreset nav section section nav aside h1,
.unreset nav section section nav nav h1,
.unreset nav section section nav section h1,
.unreset nav section section section article h1,
.unreset nav section section section aside h1,
.unreset nav section section section nav h1,
.unreset nav section section section section h1,
.unreset section article article article article h1,
.unreset section article article article aside h1,
.unreset section article article article nav h1,
.unreset section article article article section h1,
.unreset section article article aside article h1,
.unreset section article article aside aside h1,
.unreset section article article aside nav h1,
.unreset section article article aside section h1,
.unreset section article article nav article h1,
.unreset section article article nav aside h1,
.unreset section article article nav nav h1,
.unreset section article article nav section h1,
.unreset section article article section article h1,
.unreset section article article section aside h1,
.unreset section article article section nav h1,
.unreset section article article section section h1,
.unreset section article aside article article h1,
.unreset section article aside article aside h1,
.unreset section article aside article nav h1,
.unreset section article aside article section h1,
.unreset section article aside aside article h1,
.unreset section article aside aside aside h1,
.unreset section article aside aside nav h1,
.unreset section article aside aside section h1,
.unreset section article aside nav article h1,
.unreset section article aside nav aside h1,
.unreset section article aside nav nav h1,
.unreset section article aside nav section h1,
.unreset section article aside section article h1,
.unreset section article aside section aside h1,
.unreset section article aside section nav h1,
.unreset section article aside section section h1,
.unreset section article nav article article h1,
.unreset section article nav article aside h1,
.unreset section article nav article nav h1,
.unreset section article nav article section h1,
.unreset section article nav aside article h1,
.unreset section article nav aside aside h1,
.unreset section article nav aside nav h1,
.unreset section article nav aside section h1,
.unreset section article nav nav article h1,
.unreset section article nav nav aside h1,
.unreset section article nav nav nav h1,
.unreset section article nav nav section h1,
.unreset section article nav section article h1,
.unreset section article nav section aside h1,
.unreset section article nav section nav h1,
.unreset section article nav section section h1,
.unreset section article section article article h1,
.unreset section article section article aside h1,
.unreset section article section article nav h1,
.unreset section article section article section h1,
.unreset section article section aside article h1,
.unreset section article section aside aside h1,
.unreset section article section aside nav h1,
.unreset section article section aside section h1,
.unreset section article section nav article h1,
.unreset section article section nav aside h1,
.unreset section article section nav nav h1,
.unreset section article section nav section h1,
.unreset section article section section article h1,
.unreset section article section section aside h1,
.unreset section article section section nav h1,
.unreset section article section section section h1,
.unreset section aside article article article h1,
.unreset section aside article article aside h1,
.unreset section aside article article nav h1,
.unreset section aside article article section h1,
.unreset section aside article aside article h1,
.unreset section aside article aside aside h1,
.unreset section aside article aside nav h1,
.unreset section aside article aside section h1,
.unreset section aside article nav article h1,
.unreset section aside article nav aside h1,
.unreset section aside article nav nav h1,
.unreset section aside article nav section h1,
.unreset section aside article section article h1,
.unreset section aside article section aside h1,
.unreset section aside article section nav h1,
.unreset section aside article section section h1,
.unreset section aside aside article article h1,
.unreset section aside aside article aside h1,
.unreset section aside aside article nav h1,
.unreset section aside aside article section h1,
.unreset section aside aside aside article h1,
.unreset section aside aside aside aside h1,
.unreset section aside aside aside nav h1,
.unreset section aside aside aside section h1,
.unreset section aside aside nav article h1,
.unreset section aside aside nav aside h1,
.unreset section aside aside nav nav h1,
.unreset section aside aside nav section h1,
.unreset section aside aside section article h1,
.unreset section aside aside section aside h1,
.unreset section aside aside section nav h1,
.unreset section aside aside section section h1,
.unreset section aside nav article article h1,
.unreset section aside nav article aside h1,
.unreset section aside nav article nav h1,
.unreset section aside nav article section h1,
.unreset section aside nav aside article h1,
.unreset section aside nav aside aside h1,
.unreset section aside nav aside nav h1,
.unreset section aside nav aside section h1,
.unreset section aside nav nav article h1,
.unreset section aside nav nav aside h1,
.unreset section aside nav nav nav h1,
.unreset section aside nav nav section h1,
.unreset section aside nav section article h1,
.unreset section aside nav section aside h1,
.unreset section aside nav section nav h1,
.unreset section aside nav section section h1,
.unreset section aside section article article h1,
.unreset section aside section article aside h1,
.unreset section aside section article nav h1,
.unreset section aside section article section h1,
.unreset section aside section aside article h1,
.unreset section aside section aside aside h1,
.unreset section aside section aside nav h1,
.unreset section aside section aside section h1,
.unreset section aside section nav article h1,
.unreset section aside section nav aside h1,
.unreset section aside section nav nav h1,
.unreset section aside section nav section h1,
.unreset section aside section section article h1,
.unreset section aside section section aside h1,
.unreset section aside section section nav h1,
.unreset section aside section section section h1,
.unreset section nav article article article h1,
.unreset section nav article article aside h1,
.unreset section nav article article nav h1,
.unreset section nav article article section h1,
.unreset section nav article aside article h1,
.unreset section nav article aside aside h1,
.unreset section nav article aside nav h1,
.unreset section nav article aside section h1,
.unreset section nav article nav article h1,
.unreset section nav article nav aside h1,
.unreset section nav article nav nav h1,
.unreset section nav article nav section h1,
.unreset section nav article section article h1,
.unreset section nav article section aside h1,
.unreset section nav article section nav h1,
.unreset section nav article section section h1,
.unreset section nav aside article article h1,
.unreset section nav aside article aside h1,
.unreset section nav aside article nav h1,
.unreset section nav aside article section h1,
.unreset section nav aside aside article h1,
.unreset section nav aside aside aside h1,
.unreset section nav aside aside nav h1,
.unreset section nav aside aside section h1,
.unreset section nav aside nav article h1,
.unreset section nav aside nav aside h1,
.unreset section nav aside nav nav h1,
.unreset section nav aside nav section h1,
.unreset section nav aside section article h1,
.unreset section nav aside section aside h1,
.unreset section nav aside section nav h1,
.unreset section nav aside section section h1,
.unreset section nav nav article article h1,
.unreset section nav nav article aside h1,
.unreset section nav nav article nav h1,
.unreset section nav nav article section h1,
.unreset section nav nav aside article h1,
.unreset section nav nav aside aside h1,
.unreset section nav nav aside nav h1,
.unreset section nav nav aside section h1,
.unreset section nav nav nav article h1,
.unreset section nav nav nav aside h1,
.unreset section nav nav nav nav h1,
.unreset section nav nav nav section h1,
.unreset section nav nav section article h1,
.unreset section nav nav section aside h1,
.unreset section nav nav section nav h1,
.unreset section nav nav section section h1,
.unreset section nav section article article h1,
.unreset section nav section article aside h1,
.unreset section nav section article nav h1,
.unreset section nav section article section h1,
.unreset section nav section aside article h1,
.unreset section nav section aside aside h1,
.unreset section nav section aside nav h1,
.unreset section nav section aside section h1,
.unreset section nav section nav article h1,
.unreset section nav section nav aside h1,
.unreset section nav section nav nav h1,
.unreset section nav section nav section h1,
.unreset section nav section section article h1,
.unreset section nav section section aside h1,
.unreset section nav section section nav h1,
.unreset section nav section section section h1,
.unreset section section article article article h1,
.unreset section section article article aside h1,
.unreset section section article article nav h1,
.unreset section section article article section h1,
.unreset section section article aside article h1,
.unreset section section article aside aside h1,
.unreset section section article aside nav h1,
.unreset section section article aside section h1,
.unreset section section article nav article h1,
.unreset section section article nav aside h1,
.unreset section section article nav nav h1,
.unreset section section article nav section h1,
.unreset section section article section article h1,
.unreset section section article section aside h1,
.unreset section section article section nav h1,
.unreset section section article section section h1,
.unreset section section aside article article h1,
.unreset section section aside article aside h1,
.unreset section section aside article nav h1,
.unreset section section aside article section h1,
.unreset section section aside aside article h1,
.unreset section section aside aside aside h1,
.unreset section section aside aside nav h1,
.unreset section section aside aside section h1,
.unreset section section aside nav article h1,
.unreset section section aside nav aside h1,
.unreset section section aside nav nav h1,
.unreset section section aside nav section h1,
.unreset section section aside section article h1,
.unreset section section aside section aside h1,
.unreset section section aside section nav h1,
.unreset section section aside section section h1,
.unreset section section nav article article h1,
.unreset section section nav article aside h1,
.unreset section section nav article nav h1,
.unreset section section nav article section h1,
.unreset section section nav aside article h1,
.unreset section section nav aside aside h1,
.unreset section section nav aside nav h1,
.unreset section section nav aside section h1,
.unreset section section nav nav article h1,
.unreset section section nav nav aside h1,
.unreset section section nav nav nav h1,
.unreset section section nav nav section h1,
.unreset section section nav section article h1,
.unreset section section nav section aside h1,
.unreset section section nav section nav h1,
.unreset section section nav section section h1,
.unreset section section section article article h1,
.unreset section section section article aside h1,
.unreset section section section article nav h1,
.unreset section section section article section h1,
.unreset section section section aside article h1,
.unreset section section section aside aside h1,
.unreset section section section aside nav h1,
.unreset section section section aside section h1,
.unreset section section section nav article h1,
.unreset section section section nav aside h1,
.unreset section section section nav nav h1,
.unreset section section section nav section h1,
.unreset section section section section article h1,
.unreset section section section section aside h1,
.unreset section section section section nav h1,
.unreset section section section section section h1 {
  font-size: 0.67em;
  font-weight: bold;
  margin: 2.33em 0;
}
.unreset table {
  border-collapse: separate;
  border-spacing: 2px;
  border-color: gray;
}
.unreset thead,
.unreset tbody,
.unreset tfoot,
.unreset tr {
  border-color: inherit;
  vertical-align: middle;
}
.unreset td,
.unreset th {
  padding: 1px;
  vertical-align: inherit;
}
.unreset th {
  font-weight: bold;
}
.unreset caption {
  text-align: center;
}
.unreset ul,
.unreset menu {
  list-style-type: disc;
  margin: 1em 0;
  padding: 0 0 0 40px;
}
.unreset ol {
  list-style-type: decimal;
  margin: 1em 0;
  padding: 0 0 0 40px;
}
.unreset ul ul,
.unreset ol ul {
  list-style-type: circle;
}
.unreset ul ul ul,
.unreset ul ol ul,
.unreset ol ul ul,
.unreset ol ol ul {
  list-style-type: square;
}
.unreset dd {
  margin: 0 0 0 40px;
}
.unreset dl {
  margin: 1em 0;
}
.unreset ul ul,
.unreset ul ol,
.unreset ul menu,
.unreset ul dl,
.unreset ol ul,
.unreset ol ol,
.unreset ol menu,
.unreset ol dl,
.unreset menu ul,
.unreset menu ol,
.unreset menu menu,
.unreset menu dl,
.unreset dl ul,
.unreset dl ol,
.unreset dl menu,
.unreset dl dl {
  margin: 0;
}
.unreset legend {
  padding: 0 2px;
}
.unreset fieldset {
  border-style: groove;
  border-width: 2px;
  border: 2px groove ThreeDFace;
  margin: 0 2px;
  padding: 0 2px 3px;
  -webkit-padding-before: 0.35em;
  -webkit-padding-start: 0.75em;
  -webkit-padding-end: 0.75em;
  -webkit-padding-after: 0.625em;
}
.unreset ins {
  background-color: transparent;
  font-weight: inherit;
  text-decoration: underline;
}
.unreset b,
.unreset strong {
  font-weight: bold;
}
.unreset i,
.unreset cite,
.unreset em,
.unreset var,
.unreset address,
.unreset dfn {
  font-style: italic;
  font-weight: inherit;
}
.unreset abbr[title],
.unreset dfn[title] {
  border-bottom: 0;
  cursor: default;
  font-weight: inherit;
}
.unreset tt,
.unreset code,
.unreset kbd,
.unreset samp {
  font-family: monospace;
  font-weight: inherit;
}
.unreset pre {
  font-family: monospace;
  margin: 1em 0;
  white-space: pre;
}
.unreset mark {
  background-color: yellow;
  color: black;
  font-style: normal;
  font-weight: inherit;
}
.unreset big {
  font-size: larger;
  font-weight: inherit;
}
.unreset small {
  font-size: smaller;
  font-weight: inherit;
}
.unreset sub,
.unreset sup {
  font-weight: inherit;
  line-height: inherit;
  position: static;
}
.unreset sub {
  font-size: smaller;
  bottom: 0;
  vertical-align: sub;
}
.unreset sup {
  font-size: smaller;
  top: 0;
  vertical-align: super;
}
.unreset ruby > rt {
  font-size: 50%;
}
.unreset iframe {
  border: 2px inset;
}

/* Set content font-families */
